import { storeToRefs } from 'pinia'

import { useDraftStore } from '~/stores/draft'
import { useDraftPricingStore } from '~/stores/draftPricing'
import { useDraftPromoStore } from '~/stores/draftPromo'
import { useInfluencersStore } from '~/stores/influencers'
import { useMiscResizeStore } from '~/stores/miscResize'
import { useSnackbarStore } from '~/stores/snackbar'
import { useUserStore } from '~/stores/user'

import { Breakpoints } from '~/enums/breakpoints'

import type { StatsV3Influencer } from '~/types/influencer'

export type DraftOrigin = 'payin' | 'recap'

export function useDraftRefresh() {
  const { $pinia } = useNuxtApp()
  const { t } = useI18n()
  const localePath = useLocalePath()

  const draftStore = useDraftStore($pinia)
  const { GET_BY_IDS_SORTED: GET_INF_SORTED_BY_IDS } =
    useInfluencersStore($pinia)
  const { IS_PROGRESSIVE_PROMO, code: PROMO_CODE } = storeToRefs(
    useDraftPromoStore($pinia),
  )
  const { NEED_PURCHASE } = storeToRefs(useDraftPricingStore($pinia))
  const { id: DRAFT_ID, HAS_DRAFT } = storeToRefs(draftStore)
  const { SCREEN_WIDTH } = storeToRefs(useMiscResizeStore($pinia))

  const { RESET: RESET_PROMO_CODE } = useDraftPromoStore($pinia)
  const { CREATE: CREATE_SNACKBAR } = useSnackbarStore($pinia)
  const { FETCH_SET, REMOVE_INFLUENCERS_IDS: REMOVE_INFLUENCER_IDS } =
    useInfluencersStore($pinia)
  const {
    CHECK_FOR_AUTO_APPLY_PROMO,
    FETCH_UPDATED_INFLUENCERS,
    REMOVE_INF,
    REMOVE_INFLUENCER_ARRAY: REMOVE_INFLUENCER_ARRAY_FROM_DRAFT,
    RESET,
    SAVE_DRAFT_TO_LOCAL_STORAGE,
    UPDATE: UPDATE_DRAFT_ON_SERVER,
    VALIDATE_PROMO_CODE,
  } = draftStore
  const { UPDATE_GROOVIZ } = useUserStore($pinia)

  function saveTemporaryDraft() {
    if (HAS_DRAFT.value) return
    SAVE_DRAFT_TO_LOCAL_STORAGE()
    RESET()
  }

  async function draftRefresh({
    from = 'payin',
    neededGroovizBeforeRefresh = true,
  }: {
    from: DraftOrigin
    neededGroovizBeforeRefresh: boolean
  }) {
    let ret = true as boolean

    const { influencersNoLongerAvailable = [], influencersWithNewCost = [] } =
      await FETCH_UPDATED_INFLUENCERS().catch(() => {
        ret = false
        CREATE_SNACKBAR({
          props: {
            text: t('errors.draftInfluencersRefresh'),
            icon: 'fas fa-bug',
          },
        })
        return {
          influencersNoLongerAvailable: [],
          influencersWithNewCost: [],
        }
      })

    if (ret === false) return ret

    if (influencersNoLongerAvailable.length) {
      ret = false
      await handleInfluencersNoLongerAvailable(influencersNoLongerAvailable)
    }

    if (influencersWithNewCost.length) {
      ret = false
      handleInfluencersWithNewCost(influencersWithNewCost)
    }

    if ((await isWalletSynced()) === false) {
      ret = false
      handleUnsyncedWallet()
    }

    if (PROMO_CODE.value) {
      const type = IS_PROGRESSIVE_PROMO.value ? 'progressive' : 'classic'
      const isValid = await VALIDATE_PROMO_CODE(PROMO_CODE.value)
      if (!isValid) handleInvalidPromoCode({ from, type })
    } else {
      CHECK_FOR_AUTO_APPLY_PROMO()
    }

    if (ret === false)
      handleAmountToPayChanged({ from, neededGroovizBeforeRefresh })

    UPDATE_DRAFT_ON_SERVER()
    return ret
  }

  async function isWalletSynced(): Promise<boolean> {
    const { oldUserGrooviz, newUserGrooviz } = await UPDATE_GROOVIZ()
    return newUserGrooviz === oldUserGrooviz
  }

  function handleUnsyncedWallet() {
    CREATE_SNACKBAR({
      props: {
        text: t('hub.recap.snackbar.walletChanged'),
        icon: 'fas fa-exclamation-triangle',
      },
    })
  }

  async function handleInfluencersNoLongerAvailable(
    influencersUpdated: number[],
  ): Promise<void> {
    const influencers = GET_INF_SORTED_BY_IDS(influencersUpdated)

    REMOVE_INFLUENCER_ARRAY_FROM_DRAFT(influencersUpdated)
    await REMOVE_INF(influencersUpdated)
    influencers.forEach((influencer: StatsV3Influencer) => {
      CREATE_SNACKBAR({
        props: {
          text: t('hub.recap.snackbar.influencerNoLongerAvailable', {
            name: influencer.entity,
          }),
          icon: 'fas fa-exclamation-triangle',
        },
      })
    })
  }

  async function handleInfluencersWithNewCost(
    influencersWithNewCost: number[],
  ): Promise<any> {
    const influencers = GET_INF_SORTED_BY_IDS(influencersWithNewCost)

    REMOVE_INFLUENCER_IDS(influencersWithNewCost)
    await FETCH_SET(influencersWithNewCost)
    influencers.forEach((influencer: StatsV3Influencer) => {
      CREATE_SNACKBAR({
        props: {
          text: t('hub.recap.snackbar.influencerWithNewCost', {
            name: influencer.entity,
          }),
          icon: 'fas fa-exclamation-triangle',
        },
      })
    })
  }

  function handleInvalidPromoCode({
    from = 'payin',
    type = 'classic',
  }: {
    from: DraftOrigin
    type?: 'classic' | 'progressive'
  }) {
    CREATE_SNACKBAR({
      props: {
        text:
          type === 'progressive'
            ? t('hub.recap.snackbar.invalidPromoCodeDeleted')
            : t('hub.recap.snackbar.invalidProgressivePromoCode'),
        icon: 'fas fa-exclamation-triangle',
      },
    })
    RESET_PROMO_CODE()
  }

  function handleAmountToPayChanged({
    from = 'payin',
    neededGroovizBeforeRefresh = true,
  }: {
    from: DraftOrigin
    neededGroovizBeforeRefresh: boolean
  }) {
    if (from === 'recap') {
      if (!neededGroovizBeforeRefresh) {
        CREATE_SNACKBAR({
          props: {
            text: NEED_PURCHASE.value
              ? t('hub.recap.snackbar.notEnoughGrooviz')
              : t('band.payin.snackbar.campaignCostUpdated'),
            icon: 'fas fa-exclamation-triangle',
          },
        })
      } else {
        CREATE_SNACKBAR({
          props: {
            text: NEED_PURCHASE.value
              ? t('band.payin.snackbar.amountToPayUpdated')
              : t('hub.recap.snackbar.enoughGrooviz'),
            icon: 'fas fa-exclamation-triangle',
          },
        })
      }
    } else if (from === 'payin') {
      if (NEED_PURCHASE.value) {
        CREATE_SNACKBAR({
          props: {
            text: t('band.payin.snackbar.amountToPayUpdated'),
            icon: 'fas fa-exclamation-triangle',
          },
        })
      } else {
        CREATE_SNACKBAR({
          props: {
            text: t('band.payin.snackbar.youWillBeRedirected'),
            icon: 'fas fa-exclamation-triangle',
          },
        })

        return navigateTo(
          localePath({
            path:
              SCREEN_WIDTH.value < Breakpoints.lg
                ? `/draft/${DRAFT_ID.value}/recap/`
                : `/draft/${DRAFT_ID.value}/`,
            query: {
              step: SCREEN_WIDTH.value >= Breakpoints.lg ? '3' : undefined,
              snackbar: 'enoughGrooviz',
            },
          }),
        )
      }
    }
  }

  return { draftRefresh, saveTemporaryDraft } as const
}
